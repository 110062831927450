import * as React from "react";
import {GetServerSidePropsContext} from "next";
import {ApolloError, isApolloError, NormalizedCacheObject} from "@apollo/client";
import {DonorJourneyProvider} from "@donor-journey/data";
import {CheckoutDonationProvider} from "@donor-journey/data/checkout-donation-context";
import {createSerializedDonorJourneyState} from "@donor-journey/data/create-donor-journey-state";
import {PageCampaignLayout} from "@donor-journey/layouts/page-campaign-layout";
import {getCounts, getFundraiser} from "@donor-journey/requests";
import {modifiedGetFundraiserGraphqlQuery} from "@donor-journey/requests/modified-get-fundraiser-graphql-query";
import {
    checkDonateCtaAnywordExperiment,
    checkDonateMonthlyCtaExperiment,
    checkMoneyBoxDonationListPromptExperiment,
    checkSmartGoalsExperiment,
} from "@donor-journey/server-utils/check-feature";
import {handleGraphqlRequestErrors} from "@donor-journey/server-utils/handle-graphql-request-errors";
import {handleRequestErrors} from "@donor-journey/server-utils/handle-request-errors";
import {shouldQueryGqlDirectly} from "@donor-journey/server-utils/should-query-gql-directly";

import {SMART_GOALS_FUNDRAISER_EXPERIMENT} from "@campaign/experiments/use-smart-goals-fundraiser-experiment";
import {CampaignPage, useRedirectToHideImageSharingSegments} from "@campaign/features/campaign-page";
import {apolloStatePageProps, createApolloClient} from "@shared/graphql/apollo-client";
import {GetCountsDocument, GetCountsQuery, GetCountsQueryVariables, ProjectType} from "@shared/graphql/generated";
import {CACHE_TIMES, setCacheHeaders} from "@shared/server-utils/cache-headers";
import {getServerSideDistinctId} from "@shared/utils/cookie/uuid";
import {getGeoRecord} from "@shared/utils/geo-record";
import {getGfmAuthCookies, getGfmAuthCookiesString} from "@shared/utils/http-client";
import {getOptimizelyClient} from "@shared/utils/optimizely";

type CampaignPageProps = {
    searchQueryId: string;
};

type PageProps = CampaignPageProps & {
    __APOLLO_STATE__?: NormalizedCacheObject;
    donateCtaExperiment: string | null | undefined;
    donateMonthlyCtaExperiment: string | null | undefined;
    donorJourneyNewStateSerialized: string;
    goalBarsRedesignExperiment: string | null | undefined;
    isGqlFundraiserDataEnabled: boolean;
    moneyBoxDonationListExperiment: string | null | undefined;
    smartGoalsExperiment: string | null | undefined;
};

export default function Campaign(props: CampaignPageProps) {
    useRedirectToHideImageSharingSegments();

    return <CampaignPage searchQueryId={props.searchQueryId} />;
}

Campaign.getLayout = function getLayout(page: React.ReactElement<PageProps>) {
    return (
        <CheckoutDonationProvider>
            <DonorJourneyProvider
                donateCtaExperiment={page.props.donateCtaExperiment}
                donateMonthlyCtaExperiment={page.props.donateMonthlyCtaExperiment}
                goalBarsRedesignExperiment={page.props.goalBarsRedesignExperiment}
                initialStateSerialized={page.props.donorJourneyNewStateSerialized}
                isGqlFundraiserDataEnabled={page.props.isGqlFundraiserDataEnabled}
                moneyBoxDonationListExperiment={page.props.moneyBoxDonationListExperiment}
                smartGoalsExperiment={page.props.smartGoalsExperiment}
            >
                <PageCampaignLayout>{page}</PageCampaignLayout>
            </DonorJourneyProvider>
        </CheckoutDonationProvider>
    );
};

export const getServerSideProps = async (serverContext: GetServerSidePropsContext) => {
    const {req, res, query} = serverContext;
    const {cookies, url: currentRelativeUrl = ""} = req;
    const preview = query.preview ? `${query.preview}` : undefined;
    const slug = `${query.campaignUrl}`;
    const distinctId = getServerSideDistinctId({req, res});
    const geoRecord = getGeoRecord(req);
    const countryCode = geoRecord.countryCode;
    const forceGqlFundraiserData = req.cookies?.fundraiser_graphql === "true";
    const smartGoalsOverride = req.cookies?.[SMART_GOALS_FUNDRAISER_EXPERIMENT.name];
    const optimizelyClient = await getOptimizelyClient();
    const isGqlFundraiserDataEnabled = shouldQueryGqlDirectly(distinctId, forceGqlFundraiserData, optimizelyClient);
    let moneyBoxDonationListExperiment: string | null = "";
    let donateCtaExperiment: string | null = "";
    let donateMonthlyCtaExperiment: string | null = "";
    let smartGoalsExperiment: string | null = "";

    if (isGqlFundraiserDataEnabled) {
        const apolloClient = createApolloClient();

        try {
            const gfmAuth = getGfmAuthCookies(req.cookies);
            const apolloResponse = await modifiedGetFundraiserGraphqlQuery({apolloClient, gfmAuth, slug});
            const fundCountryCode = apolloResponse.data?.fundraiser?.location?.countryCode || "";
            const fundCurrencyCode = apolloResponse.data?.fundraiser?.currentAmount?.currencyCode || "";
            const isCharityFundraiser =
                apolloResponse.data?.fundraiser?.projectType === ProjectType.CHARITY ||
                apolloResponse.data?.fundraiser?.projectType === ProjectType.DIRECT_TO_CHARITY;

            moneyBoxDonationListExperiment = checkMoneyBoxDonationListPromptExperiment(
                distinctId,
                apolloResponse.data?.fundraiser?.currentAmount?.currencyCode || "",
                optimizelyClient,
            );
            donateCtaExperiment = checkDonateCtaAnywordExperiment(
                distinctId,
                {
                    countryCode,
                    fundCountryCode,
                },
                optimizelyClient,
            );
            donateMonthlyCtaExperiment = checkDonateMonthlyCtaExperiment(
                distinctId,
                {
                    fundCurrencyCode,
                    isCharityFundraiser,
                },
                optimizelyClient,
            );
            smartGoalsExperiment = checkSmartGoalsExperiment(distinctId, {fundCountryCode}, optimizelyClient);

            await apolloClient.query<GetCountsQuery, GetCountsQueryVariables>({
                query: GetCountsDocument,
                variables: {
                    slug,
                },
                context: {
                    headers: {
                        cookie: getGfmAuthCookiesString(gfmAuth),
                    },
                    retryPolicy: {
                        shouldRetry: true,
                    },
                },
            });

            if (query["cdn-cache"] !== "0" && apolloResponse?.data?.fundraiser?.isLaunched) {
                setCacheHeaders(res, {
                    staleWhileRevalidate: CACHE_TIMES.TEN_MINUTES,
                    staleIfError: CACHE_TIMES.ONE_HOUR,
                });
            }
        } catch (error) {
            if (isApolloError(error as Error)) {
                return handleGraphqlRequestErrors(error as ApolloError, {req, query});
            }

            return handleRequestErrors({error: error as Error, currentRelativeUrl});
        }

        return {
            props: {
                ...(apolloClient && apolloStatePageProps(apolloClient)),
                donateCtaExperiment,
                donateMonthlyCtaExperiment,
                isGqlFundraiserDataEnabled,
                moneyBoxDonationListExperiment,
                searchQueryId: query.qid ? `${query.qid}` : null,
                smartGoalsExperiment: smartGoalsOverride || smartGoalsExperiment,
            },
        };
    }

    return getFundraiser({campaignUrl: slug, serverCookies: cookies, preview})
        .then(async (campaignResponse) => {
            const fundCountryCode = campaignResponse?.references?.campaign?.location?.country || "";
            const fundCurrencyCode = campaignResponse?.references?.campaign?.currencycode || "";
            const isCharityFundraiser =
                campaignResponse?.references?.campaign?.project_type === 2 ||
                campaignResponse?.references?.campaign?.project_type === 4 ||
                false;

            moneyBoxDonationListExperiment = checkMoneyBoxDonationListPromptExperiment(
                distinctId,
                fundCurrencyCode,
                optimizelyClient,
            );
            donateCtaExperiment = checkDonateCtaAnywordExperiment(
                distinctId,
                {
                    countryCode,
                    fundCountryCode,
                },
                optimizelyClient,
            );
            donateMonthlyCtaExperiment = checkDonateMonthlyCtaExperiment(
                distinctId,
                {
                    fundCurrencyCode,
                    isCharityFundraiser,
                },
                optimizelyClient,
            );
            smartGoalsExperiment = checkSmartGoalsExperiment(distinctId, {fundCountryCode}, optimizelyClient);

            const countsResponse = await getCounts({campaignUrl: slug});

            if (query["cdn-cache"] !== "0" && campaignResponse.references.campaign.is_launched) {
                setCacheHeaders(res, {
                    staleWhileRevalidate: CACHE_TIMES.TEN_MINUTES,
                    staleIfError: CACHE_TIMES.ONE_HOUR,
                });
            }

            return {
                props: {
                    donateCtaExperiment,
                    donateMonthlyCtaExperiment,
                    donorJourneyNewStateSerialized: createSerializedDonorJourneyState({
                        campaignResponse,
                        countsResponse,
                    }),
                    moneyBoxDonationListExperiment,
                    searchQueryId: query.qid ? `${query.qid}` : null,
                    smartGoalsExperiment: smartGoalsOverride || smartGoalsExperiment,
                },
            };
        })
        .catch((error) => handleRequestErrors({error, currentRelativeUrl}));
};
